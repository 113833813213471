import service from '@/utils/request.js'

/**
 * 注册
 */
export function register(data={}) {
  return service.request({
    url: '/api/user/register',
    method: 'post',
    data
  })
}


/**
 * 修改密码
 */
export function resetpwd(data={}) {
  return service.request({
    url: '/api/user/resetpwd',
    method: 'post',
    params:data
  })
}

/**
 * 登录
 */
export function login(data={}) {
  return service.request({
    url: '/api/user/login',
    method: 'post',
    params:data
  })
}

/**
 * 
 * @param {查看业务介绍} data 
 */
export function webexplain(data={}) {
  return service.request({
    url: '/api/index/webexplain',
    method: 'get',
    params:data
  })
}


/**
 * 
 * @param {获取国内中转仓库地址} data 
 */
export function settings(data={}) {
  return service.request({
    url: '/api/index/settings',
    method: 'get',
    params:data
  })
}


/**
 * 
 * @param {获取首页数据} data 
 */
export function goods(data={}) {
  return service.request({
    url: '/api/index/goods',
    method: 'get',
    params:data
  })
}


/**
 * 
 * @param {加国淘宝} data 
 */
export function apptb(data={}) {
  return service.request({
    url: '/api/center/apptb',
    method: 'get',
    params:data
  })
}



/**
 * 
 * @param {意见反馈} data 
 */
export function feedback(data={}) {
  return service.request({
    url: '/api/center/feedback',
    method: 'post',
    params:data
  })
}




/**
 * 
 * @param {获取省市区} data 
 */
export function area(data={}) {
  return service.request({
    url: '/api/center/area',
    method: 'post',
    params:data
  })
}



/**
 * 
 * @param {添加收货地址} data 
 */
export function addaddress(data={}) {
  return service.request({
    url: '/api/center/addaddress',
    method: 'post',
    params:data
  })
}


/**
 * 
 * @param {查看收货地址} data 
 */
export function showaddress(data={}) {
  return service.request({
    url: '/api/center/showaddress',
    method: 'post',
    params:data
  })
}


/**
 * 
 * @param {编辑收货地址} data 
 */
export function editaddress(data={}) {
  return service.request({
    url: '/api/center/editaddress',
    method: 'post',
    params:data
  })
}



/**
 * 
 * @param {删除收货地址} data 
 */
export function deladdress(data={}) {
  return service.request({
    url: '/api/center/deladdress',
    method: 'post',
    params:data
  })
}

/**
 * 
 * @param {提交订单} data 
 */
export function addorder(data={}) {
  return service.request({
    url: '/api/order/addorder',
    method: 'post',
    params:data
  })
}



/**
 * 
 * @param {订单列表} data 
 */
export function order(data={}) {
  return service.request({
    url: '/api/center/order',
    method: 'post',
    params:data
  })
}

/**
 * 
 * @param {万能接口} data 
 */
export function getset(data={}) {
  return service.request({
    url: '/api/center/getset',
    method: 'post',
    params:data
  })
}

/**
 * 
 * @param {获取详情} data 
 */
export function about(data={}) {
  return service.request({
    url: '/api/center/about',
    method: 'post',
    params:data
  })
}


/**
 * 
 * @param {获取详情} data 
 */
export function getqu(data={}) {
  return service.request({
    url: '/api/user/getqu',
    method: 'post',
    params:data
  })
}

/**
 * 
 * @param {五级联动} data 
 */
export function worldarea(data={}) {
  return service.request({
    url: '/api/center/worldarea',
    method: 'post',
    params:data
  })
}















/**
 * 上传图片
 */
export function upload_image(data={}) {
  let formData = new FormData()
  console.log(data)
  formData.append('image', data.image)
  return service.request({
    url: '/admin/purchase/upload',
    method: 'post',
    type: 'upload',
    data: formData
  })
}

