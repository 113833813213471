import axios from 'axios'
import {Message} from 'element-ui'


const service = axios.create({
     // 本地
    //  baseURL:'http://www.jiaguo.com/',
     // 国内线上
    //  baseURL:'http://121.196.103.197:3333/',
     // 国外线上
     baseURL:'https://xiao.ecgbuy.com/',
    //  国外线上新地址
    //  baseURL:'https://airseascargo.com/',
     
});

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    let token = JSON.parse(sessionStorage.getItem('token'))
    if(token){
        config.headers.token = token;
    }
    if (config.type == 'upload') {
        config.headers['Content-Type'] = 'multipart/form-data;'
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 添加响应拦截器
service.interceptors.response.use(function (response) {
    let data = response.data
    switch (data.code){
        case 1:
            return data;
        case 401:
            Message.error(data.msg);
            sessionStorage.removeItem('token')
            // router.replace('/login')
            break;
        case 0:
            Message.error(data.msg);
            return data;
        default:
            Message.error(data.msg);
            break;

    }
}, function (error) {
    let status = error.response.status
    if(status == 401){
        sessionStorage.removeItem('token')
        Message.error(error.response.data.msg);
    }

    return Promise.reject(error);
});

export default service;